import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import { Button, Card, Skeleton, Carousel } from '@opsdti-global-component-library/amgen-design-system';
import { createChunks } from '../../utils';
import DynamicList from 'components/elements/dynamic-list';
import VideoPlayer from 'components/elements/video-player';
import NewsLetters from 'components/elements/sensing-newsletter';
import parse from 'html-react-parser';
import './index.scss';
import '../home.scss';
import ReleaseNotes from '../../components/release-notes-section';
import { useAboutPageNavManager } from 'hooks/use-nav-manager';
import { useLocation } from 'react-router-dom';
import { ReleaseNotesContextProvider } from 'contexts/release-notes-context';
import { getAboutPageData, postAboutPageData, getAboutPageImg } from 'api/about-page';
import { useOktaAuth } from '@okta/okta-react';
import ImgVideoUploadModal from 'components/elements/image-video-modal';
import { AuthPermissions } from 'types';
import { CarouselContent, Content, tileContentObj } from './about';
import tinymce from 'tinymce/tinymce';
import 'tinymce/plugins/link';
import 'tinymce/themes/silver';
// DOM model
import 'tinymce/models/dom/model'
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/lists'; // for bullet and numbered lists
import { Link } from 'react-router-dom';
import Header from './header';
interface AboutPageProps {
  tilePermissions: AuthPermissions;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const slides = (s: CarouselContent[][], tileKeyObj: { key: string; structure_id: string; content_id: string; }, isSlideEditable: boolean, onTextChange: Function, openImageModal: Function) => {
  return <Carousel className="separator" slidesToShow={1} items={
    s.map((i, idx) => {
      return (<div key={'carousel_' + idx} className={`about-carousel-grid ${tileKeyObj.key}`}>
        {i.map((item, index) => {
          const itemObj = {
            details_id: item.details_id,
            structure_id: tileKeyObj.structure_id,
            content_id: tileKeyObj.content_id,
          };
          return (
            <>
              <div
                key={`carousel_${idx}_item_${index.toString()}`}
                className="d-flex flex-column about-carousel-item-wrap"
              >
                <div className="h-100 d-flex flex-column">
                  <>
                    {item?.image && (
                      <div className="about-carousel-grid-item img-upload">
                        <img
                          className="about-carousel-image"
                          key={item?.heading}
                          src={item?.image}
                          alt={item.subHeading}
                        />
                        {isSlideEditable ? <Button type='primary' className={'upload-content'} onClick={() => openImageModal(item.imgKey)} text='upload'></Button> : <></>}
                      </div>)}
                    {item.href && item.linkTitle && item.text && (
                      <><div className="about-carousel-grid-item">
                        <p className='about-carousel-title'>{item.linkTitle}</p>
                        {item.subHeading && (
                          <div className="about-carousel-nav-text" >
                            {item.subHeading}
                          </div>
                        )}
                      </div>


                        <div className="about-carousel-grid-item">
                          <div className="about-carousel-text main-description" contentEditable={isSlideEditable} suppressContentEditableWarning={true} data-id={itemObj.content_id + '-' + item.details_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, itemObj.structure_id)}>{parse(item?.text)}</div>
                        </div>

                        <Button
                          type="primary"
                          className="about-carousel-nav-button"
                          href={item.href}
                          target='_blank'
                          text={"Go to " + item?.linkTitle} />
                      </>
                    )}
                  </>
                </div>

              </div>
              {s.length % 2 !== 0 && i.length < 2 && window.innerWidth > 1024 ? <div className="d-flex flex-column about-carousel-item-wrap"></div> : null}
            </>)
        })}
      </div>)
    })
  } />
};

export const AboutSensing = (props: AboutPageProps): JSX.Element => {
  useAboutPageNavManager();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);
  const [aboutPageData, setAboutPageData] = useState<{ key: string; "title": string; content: Content[] }[]>([]);
  const [aboutPageSections, setAboutPageSections] = useState<{ newsLetters: any, whatsNew: any, intro: any, security: any, infoData: any, vision: any, structure: any, views: any }>({ newsLetters: {}, whatsNew: {}, intro: {}, security: {}, infoData: {}, vision: {}, structure: {}, views: {} });
  const [aboutPageImgData, setAboutPageImgData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [ImgUploadModalOpen, setImgUploadModalOpen] = useState(false);
  const [imageKey, setImageKey] = useState<any>('');
  const introRef = useRef<HTMLDivElement>(null);
  const securityRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const visionRef = useRef<HTMLDivElement>(null);
  const structureRef = useRef<HTMLDivElement>(null);
  const viewsRef = useRef<HTMLDivElement>(null);
  const whatsNewRef = useRef<HTMLDivElement>(null);
  const releaseNotesRef = useRef<HTMLDivElement>(null);
  const newsLettersRef = useRef<HTMLDivElement>(null);
  const [contentEditableEdit, setContentEditableEdit] = useState(false);
  const [contentUploaded, setContentUploaded] = useState(false);
  const { authState } = useOktaAuth();
  const [textUpdates, setTextUpdates] = useState<{ [id: string]: string }[]>([]); //for storing text updates
  const { tilePermissions } = props;
  const [disableEdit, setDisableEdit] = useState(false);

  const initTinyMCE = () => {
    tinymce.init({
      selector: 'div[contenteditable="true"]',  // change this value according to your HTML
      inline: true,
      menubar: false,
      plugins: ['link', 'lists'], // include 'lists' plugin
      toolbar: 'undo redo bold italic underline link',
      promotions: false
    });
  }

  //Edit content
  const editContent = () => {
    setContentEditableEdit(true);
    setTimeout(() => {
      initTinyMCE();
    }, 100);

  };

  //Save content
  const saveContent = async () => {
    setDisableEdit(true);
    const objToSave = textUpdates.map((obj) => {
      const key = Object.keys(obj)[0];
      if (key.split('-').length === 3) {
        //details text
        return {
          structure_id: Number(key.split('-')[0]),
          //content_id: Number(key.split('-')[1]), If there is detail_id, then content_id is not required
          content_id: 0,
          details_id: Number(key.split('-')[2]),
          details_text: obj[key],
          content_text: '',
          content_sub_text: ''
        }
      } else {
        //content text
        return {
          structure_id: Number(key.split('-')[0]),
          content_id: Number(key.split('-')[1]),
          details_id: 0,
          details_text: '',
          content_text: obj[key],
          content_sub_text: ''
        }
      }
    })

    const res = await postAboutPageData(authState?.accessToken?.accessToken, { 'about_list': objToSave });
    if (res?.data?.status === 'SUCCESS') {
      alert('Content saved successfully');
      setContentEditableEdit(false);
    } else {
      alert('Something went wrong, please try again.');
    }
    setTextUpdates([]);
    setDisableEdit(false);
  }

  const resetContent = () => {
    setContentEditableEdit(false);
    getAboutPageContent();
    setAboutPageData([]);
    setTextUpdates([]);
  }

  const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
    intro: introRef,
    security: securityRef,
    info: infoRef,
    vision: visionRef,
    newsletters: newsLettersRef,
    structure: structureRef,
    views: viewsRef,
    whatsnew: whatsNewRef,
    notes: releaseNotesRef,
  };

  const ImgVideoKeys = ['video/whats-new-in-sensing-2023-video.mp4', 'video/tired_of_waiting_for_answers_video.mp4', 'image/01_home_page.jpg', 'image/02_tiles.jpg', 'image/03_pages.jpg', 'image/about-info-domains.jpg', 'image/about-logo.png', 'image/Amgen-Sensing.png', 'image/brand_page_view.jpg', 'image/external_page_view.jpg', 'image/finance_page_view.jpg', 'image/PA Redacted.jpg', 'image/people_page_view.jpg', 'image/pipeline_page_view.jpg', 'image/supply_page_view.jpg', 'image/video-poster.jpg', 'image/what-is-sensing-about.jpg', 'image/whatsNewImg.png', 'image/Home Redacted.jpg', 'image/sensing-scope.png', 'image/key-events.jpg']
  const getRef = (key: string): React.RefObject<HTMLDivElement> | null => {
    return refs[key] || null;
  };

  useEffect(() => {
    getAboutPageContent();
  }, []);

  useEffect(() => {
    //for mapping image and video's S3 link with about page data 
    if (aboutPageData.length === 0 && aboutPageImgData.length !== ImgVideoKeys.length) {
      return
    }
    const updateUrl = aboutPageData;
    const originalValues: { [id: string]: string }[] = []; //Collecting original text, Object with 'text' should be stored
    updateUrl.map((tileInfo: any) => {
      const structureId = tileInfo.structure_id;
      tileInfo.content?.map((content: any) => {
        const contentId = content.content_id;
        const contentKey = structureId + '-' + contentId;
        if (content?.text && originalValues.map((key: any) => Object.keys(key)[0]).indexOf(contentKey) === -1) {
          originalValues.push({ [contentKey]: content.text })
        }
        if (content?.list?.listcontent) {
          const listInfo = content?.list?.listcontent;
          aboutPageImgData?.map((keyInfo: any) => {
            listInfo?.map((listContent: any) => {
              const detailsId = listContent?.details_id;
              const detailkey = structureId + '-' + contentId + '-' + detailsId;
              if (keyInfo.key === listContent?.image) {
                return (
                  listContent.image = keyInfo.imageUrl,
                  listContent.imgKey = keyInfo.key
                )
              }

              if (listContent?.text && originalValues.map((key: any) => Object.keys(key)[0]).indexOf(detailkey) === -1) {
                originalValues.push({ [detailkey]: listContent?.text })
              }

            })
          })

        }
        if (content?.video) {
          const videoInfo = content.video;
          aboutPageImgData?.map((keyInfo: any) => {
            if (keyInfo.key === videoInfo.poster) {
              return (
                videoInfo.poster = keyInfo.imageUrl,
                videoInfo.posterKey = keyInfo.key
              )
            } else if (keyInfo.key === videoInfo.src) {
              return (
                videoInfo.src = keyInfo.imageUrl,
                videoInfo.videoKey = keyInfo.key
              )
            }
          })
        }
      })
    })
    setAboutPageFields(updateUrl);
    setContentUploaded(false);
    setContentEditableEdit(false);

  }, [aboutPageImgData]);

  const getAboutPageContent = async () => {
    try {
      setLoading(true);
      const res = await getAboutPageData(authState?.accessToken?.accessToken);
      if (res?.data?.data.length > 0) {
        Promise.all([validateMediaUrls()]).then(() => {
          setAboutPageData(res.data.data); //just to trigger hook
        });

      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  const getAboutPageImgContent = () => {
    try {
      let imgContent: any = [];
      ImgVideoKeys.map(async (imgKey) => {
        const res = await getAboutPageImg(authState?.accessToken?.accessToken, imgKey);
        if (res?.status === 200) {

          imgContent = [...imgContent, res.data]
          setAboutPageImgData(imgContent);
          if (imgContent.length === ImgVideoKeys.length) {
            //localStorage.setItem(`${process.env.REACT_APP_ENV + '-media'}`, JSON.stringify(imgContent));
            //localStorage.setItem(`${process.env.REACT_APP_ENV + '-media-time'}`, new Date().getTime().toString());
          }

        } else {
          console.log(res);
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  const validateMediaUrls = () => {
    /* Disabling Cache
    if (localStorage.getItem(`${process.env.REACT_APP_ENV + '-media'}`) !== null && localStorage.getItem(`${process.env.REACT_APP_ENV + '-media-time'}`) !== null) {
      //if media urls are already stored in local storage
      const currentTime = new Date().getTime();
      const storedTime = Number(localStorage.getItem(`${process.env.REACT_APP_ENV + '-media-time'}`));
      const preSignedUrlExpiry = 518400; // in sec for 6 days

      if ((currentTime - storedTime) / 1000 > preSignedUrlExpiry) {
        getAboutPageImgContent()
        return
      }

      const imgContentLocal = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_ENV + '-media'}`) || '{}');

      setAboutPageImgData(imgContentLocal);
      //axios.all(ImgVideoKeys.map((imgKey) => getAboutPageImg(authState?.accessToken?.accessToken, imgKey)))
    } else {
      getAboutPageImgContent();
    } */
    getAboutPageImgContent();
  }

  const setAboutPageFields = (aboutPageData: any) => {

    const newsLetterDataObj = aboutPageData.filter((obj: any) => obj.structure_id === 8)[0];
    const whatsNewVideo = aboutPageData.filter((obj: any) => obj.structure_id === 7)[0];
    const introData = aboutPageData.filter((obj: any) => obj.structure_id === 2)[0];
    const securityData = aboutPageData.filter((obj: any) => obj.structure_id === 4)[0];
    const infoData = aboutPageData.filter((obj: any) => obj.structure_id === 3)[0];
    const visionData = aboutPageData.filter((obj: any) => obj.structure_id === 1)[0];
    const structureData = aboutPageData.filter((obj: any) => obj.structure_id === 5)[0];
    const viewsData = aboutPageData.filter((obj: any) => obj.structure_id === 6)[0];

    setAboutPageSections({
      newsLetters: { newsLetters: newsLetterDataObj.content[0].newsLetters, key: newsLetterDataObj.key, title: newsLetterDataObj.title, structure_id: newsLetterDataObj.structure_id },
      whatsNew: whatsNewVideo,
      intro: { ...introData },
      security: { ...securityData },
      infoData: { ...infoData, listIndx: infoData.content.findIndex((obj: any) => obj.content_type === 'list'), listImgIndx: infoData.content.findIndex((obj: any) => obj.content_type === 'list-image') },
      vision: {
        ...visionData,
        videoIndx: visionData.content.findIndex((obj: any) => obj.content_type === 'video'),
        listIconIndx: visionData.content.findIndex((obj: any) => obj.content_type === 'list-icon')
      },
      structure: {
        ...structureData,
        listIndx: structureData.content.findIndex((obj: any) => obj.content_type === 'list'),
        listMobIndx: structureData.content.findIndex((obj: any) => obj.content_type === 'list-image' && obj.list.listcontent.length > 2),
        listImgIndx: structureData.content.findIndex((obj: any) => obj.content_type === 'list-image' && obj.list.listcontent.length < 2),
        textIndx: structureData.content.findIndex((obj: any) => obj.content_type === 'text')
      },
      views: { ...viewsData },
    });

    setAboutPageData(aboutPageData);
  }

  const formatTileContent = (tile: tileContentObj) => {
    return tile?.key !== 'structure'
      ? (tile.content as Content[])
      : isMobile
        ? ([tile.content[0], tile.content[3]] as Content[])
        : ([tile.content[1], tile.content[2], tile.content[3]] as Content[]);
  };

  useLayoutEffect(() => {
    const isMobileOnResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    isMobileOnResize();
    //isRightNavCollapsedOnResize();
    window.addEventListener('resize', isMobileOnResize);

    return () => {
      window.removeEventListener('resize', isMobileOnResize);
    };
  });

  const location = useLocation();

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToSection = (): void => {
    if (location) {
      const currentRef: React.RefObject<HTMLDivElement> | null = getRef(
        location.hash.slice(1),
      );

      if (currentRef && currentRef.current) {
        const isElementInView = isInViewport(currentRef.current);

        if (!isElementInView) {
          window.scroll({
            top: currentRef.current.getBoundingClientRect().top - 60,
            behavior: 'auto',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        scrollToSection();
      }, 100);
    }
  }, [location.hash]);

  useEffect(() => {
    if (contentUploaded) {
      //When Some content is uploaded, reset the textUpdates and get the updated content
      localStorage.removeItem(`${process.env.REACT_APP_ENV + '-media'}`);
      localStorage.removeItem(`${process.env.REACT_APP_ENV + '-media-time'}`);

      getAboutPageContent();
      setImgUploadModalOpen(false);
    }
  }, [contentUploaded]);

  const onTextChange = (e: React.FocusEvent<HTMLDivElement>, structureId: string) => {
    const textUpddatesKeys = textUpdates.map((key) => Object.keys(key)[0]);
    const contentDetailsId = (e.target as HTMLDivElement).getAttribute('data-id');
    let updatedText = '';
    const key = structureId + '-' + contentDetailsId;
    const keyIndex = textUpddatesKeys.indexOf(key);

    if ((e.target as HTMLDivElement).querySelectorAll('p').length > 1) {
      updatedText = (e.target as HTMLDivElement).innerHTML;
    } else if ((e.target as HTMLDivElement).querySelectorAll('p').length === 1) {
      updatedText = (e.target as HTMLDivElement).children[0].innerHTML;
    } else {
      updatedText = (e.target as HTMLDivElement).innerHTML;
    }
    if (keyIndex > -1) {
      textUpdates[keyIndex][key] = updatedText;

    } else {
      textUpdates.push({ [key]: updatedText })
    }
    setTextUpdates(textUpdates);

  }

  const openImageModal = (imgName: string) => {
    setImgUploadModalOpen(true);
    setImageKey(imgName);
  }

  return (
    <>
      <div className="about-page-container">
        <Header page='about-page' />
        {isLoading ? (
          <Skeleton paragraph={{ rows: 20 }} className='about-page-skeleton' />) :
          <div className='about-page-main'>
            {tilePermissions?.aboutValue && (
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="px-0">
                  <>
                    {(tilePermissions?.aboutEdit) &&
                      <>{contentEditableEdit ? <div className='set-edit-btns'><Button onClick={saveContent} type='secondary' text='Save Content' disabled={disableEdit} loading={disableEdit}></Button>
                        <Button onClick={resetContent} type='secondary' text='Reset Content'></Button></div> : <div className='set-edit-btns'><Button onClick={editContent} type='secondary' text='Edit Content'></Button></div>}</>

                    }
                  </>
                  <div className={`collapse navbar-collapse show `} id="navbarNav">
                    <ul className="navbar-nav border-bottom about-section-name">
                      <li key={'about'} className="nav-item">
                        <Link className='nav-link active px-0' to={'/about'}>
                          <span className="menu-text">{'About'}</span>
                        </Link>
                      </li>

                      <li key={'value-capture'} className="nav-item">
                        <Link className='nav-link px-0' to={'/about/value-capture'}>
                          <span className="menu-text">{'Value Capture'}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            )}
            <div className="about-page-flex">

              <div className='about-page-left-col'>

                {/*****************  Intro Content *************/}
                {aboutPageSections.intro.structure_id !== undefined ?
                  <div id={aboutPageSections.intro.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.intro.key)}>
                    <Card className="ops-metric-card " title={aboutPageSections.intro.title}>
                      <div className='about-page-text video-whats-new'>
                        <div
                          key={aboutPageSections.intro.key}
                          suppressContentEditableWarning={true}
                          contentEditable={contentEditableEdit} data-id={aboutPageSections.intro.content[0].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.intro.structure_id)}
                          className={`about-page-text `}
                        >
                          <>{parse(aboutPageSections.intro.content[0].text)}</>
                        </div>

                        <div
                          key={aboutPageSections.whatsNew.key} className=''>
                          <VideoPlayer src={aboutPageSections.whatsNew.content[0].video.src} width={aboutPageSections.whatsNew.content[0].video.width} poster={aboutPageSections.whatsNew.content[0].video.poster} title={aboutPageSections.whatsNew.content[0].video.title} contentEditable={contentEditableEdit} videoKey={aboutPageSections.whatsNew.content[0].video?.videoKey} posterKey={aboutPageSections.whatsNew.content[0].video?.posterKey} contentUpdate={() => setContentUploaded(true)} />
                        </div>
                      </div>
                    </Card>
                  </div> : <></>}
                {/*****************  Vision Content *************/}
                {aboutPageSections.vision.structure_id !== undefined ?
                  <div id={aboutPageSections.vision.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.vision.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.vision.title}>
                      <div className='vision-content'>
                        <div className='vision-content__list'>
                          <div className='about-page-text'
                            key={aboutPageSections.vision.key}
                            suppressContentEditableWarning={true}
                            contentEditable={contentEditableEdit} data-id={aboutPageSections.vision.content[aboutPageSections.vision.listIconIndx].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.vision.structure_id)}
                          >{parse(aboutPageSections.vision.content[aboutPageSections.vision.listIconIndx].text)}</div>
                          <DynamicList contentEditable={contentEditableEdit} onTextChange={onTextChange} structureId={aboutPageSections.vision.structure_id} content={aboutPageSections.vision.content[aboutPageSections.vision.listIconIndx]} />
                        </div>
                        <div className=''>
                          <div className='about-page-text' key={aboutPageSections.vision.key}
                            suppressContentEditableWarning={true}
                            contentEditable={contentEditableEdit} data-id={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.vision.structure_id)}>{parse(aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].text)}</div>
                          <VideoPlayer src={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video.src}
                            width={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video.width}
                            poster={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video.poster}
                            title={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video.title}
                            contentEditable={contentEditableEdit} videoKey={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video.videoKey}
                            posterKey={aboutPageSections.vision.content[aboutPageSections.vision.videoIndx].video?.posterKey}
                            contentUpdate={() => setContentUploaded(true)} />
                        </div>
                      </div>
                    </Card>
                  </div> : <></>}

                {/*****************  Info Content *************/}
                {aboutPageSections.infoData.structure_id !== undefined ?
                  <div id={aboutPageSections.infoData.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.infoData.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.infoData.title}>
                      <div className='vision-content info-content'>
                        <div className='vision-content__video'>
                          <div className='img-upload'>
                            <img
                              className="card-image"
                              src={aboutPageSections.infoData?.content[aboutPageSections.infoData.listImgIndx]?.list?.listcontent[0].image}
                              alt={'Info Content'}
                            />
                            {contentEditableEdit ? <Button type='primary' className={'upload-content img-upload-button'} onClick={() => { setImageKey(aboutPageSections.infoData?.content[aboutPageSections.infoData.listImgIndx]?.list?.listcontent[0].imgKey); setImgUploadModalOpen(true); }} text='upload'></Button> : <></>}
                          </div>
                        </div>
                        <div className='vision-content__list'>
                          <div className='about-page-text'>{parse(aboutPageSections.infoData.content[aboutPageSections.infoData.listIndx].text)}</div>
                          <DynamicList contentEditable={false} onTextChange={onTextChange} structureId={aboutPageSections.infoData.structure_id} content={aboutPageSections.infoData.content[aboutPageSections.infoData.listIndx]} />
                          <div className='about-page-text'>{parse(aboutPageSections.infoData.content[aboutPageSections.infoData.listIndx].subtext)}</div>
                        </div>
                      </div>
                    </Card>
                  </div> : <></>}
                {/*****************  Security Content *************/}
                {aboutPageSections.security.structure_id !== undefined ?
                  <div id={aboutPageSections.security.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.security.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.security.title}>
                      <div
                        key={aboutPageSections.security.key}
                        suppressContentEditableWarning={true}
                        contentEditable={contentEditableEdit} data-id={aboutPageSections.security.content[0].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.intro.structure_id)}
                        className={`about-page-text `}
                      >
                        <>{parse(aboutPageSections.security.content[0].text)}</>
                      </div>
                    </Card>
                  </div> : <></>}
                {/*****************  How sensing is structured Content *************/}
                {aboutPageSections.structure.structure_id !== undefined ?
                  <div id={aboutPageSections.structure.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.structure.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.structure.title}>

                      <div className='sensing-structured-content'>
                        <div className='sensing-structured-content__list'>
                          <div className='about-page-text'>{parse(aboutPageSections.structure.content[aboutPageSections.structure.listIndx].text)}</div>
                          <DynamicList contentEditable={contentEditableEdit} onTextChange={onTextChange} structureId={aboutPageSections.structure.structure_id} content={isMobile ? aboutPageSections.structure.content[aboutPageSections.structure.listMobIndx] : aboutPageSections.structure.content[aboutPageSections.structure.listIndx]} />
                        </div>
                        <>{!isMobile ?
                          <div className='sensing-structured-content__img'>
                            <div className='img-upload'>
                              <img
                                className="card-image"
                                src={aboutPageSections.structure?.content[aboutPageSections.structure.listImgIndx]?.list?.listcontent[0].image}
                                alt={'Structure Content'}
                              />
                              {contentEditableEdit ? <Button type='primary' className={'upload-content img-upload-button'} onClick={() => { setImgUploadModalOpen(true); setImageKey(aboutPageSections.structure?.content[aboutPageSections.structure.listImgIndx]?.list?.listcontent[0]?.imgKey) }} text='upload'></Button> : <></>}
                            </div>

                          </div> : <></>}</>
                        <div className='sensing-structured-content__text about-page-text' key={aboutPageSections.structure.key}
                          suppressContentEditableWarning={true}
                          contentEditable={contentEditableEdit} data-id={aboutPageSections.structure.content[aboutPageSections.structure.textIndx].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.structure.structure_id)}>{parse(aboutPageSections.structure.content[aboutPageSections.structure.textIndx].text)}</div>
                      </div>

                    </Card>
                  </div> : <></>}

                {/*****************  Carousel Content *************/}
                {aboutPageSections.views.structure_id !== undefined ?
                  <div id={aboutPageSections.views.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.views.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.views.title}>

                      {slides(
                        createChunks(formatTileContent({ key: aboutPageSections.views.key, content: aboutPageSections.views.content[0].list?.listcontent }), isMobile ? 1 : 2),
                        { key: aboutPageSections.views.key, structure_id: aboutPageSections.views.structure_id, content_id: aboutPageSections.views.content[0].content_id },
                        contentEditableEdit,
                        onTextChange,
                        openImageModal,
                      )}
                    </Card>
                  </div> : <></>}
              </div >
              {/*****************  Right Panel *************/}
              <div className='about-page-right-col'>
                {/* {aboutPageSections.whatsNew.structure_id !== undefined ?
                  <div id={aboutPageSections.whatsNew.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.whatsNew.key)}>
                    <Card className="ops-metric-card" title={aboutPageSections.whatsNew.title}>

                      <div
                        key={aboutPageSections.whatsNew.key}
                        suppressContentEditableWarning={true}
                        contentEditable={contentEditableEdit} data-id={aboutPageSections.whatsNew.content[0].content_id} onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, aboutPageSections.whatsNew.structure_id)}
                        className={`about-page-text `}
                      >
                        <>{parse(aboutPageSections.whatsNew.content[0].text)}</>
                      </div>
                      <VideoPlayer src={aboutPageSections.whatsNew.content[0].video.src} width={aboutPageSections.whatsNew.content[0].video.width} poster={aboutPageSections.whatsNew.content[0].video.poster} title={aboutPageSections.whatsNew.content[0].video.title} contentEditable={contentEditableEdit} videoKey={aboutPageSections.whatsNew.content[0].video?.videoKey} posterKey={aboutPageSections.whatsNew.content[0].video?.posterKey} contentUpdate={() => setContentUploaded(true)} />

                    </Card>
                  </div> : <></>} */}
                {aboutPageSections.newsLetters.key !== undefined ?
                  <div id={aboutPageSections.newsLetters.key} className={`about-page-grid-item`} ref={getRef(aboutPageSections.newsLetters.key)}>
                    <Card className="ops-metric-card newsletter-section" title={aboutPageSections.newsLetters.title}>
                      <div
                        key={aboutPageSections.newsLetters.key}
                        suppressContentEditableWarning={true}
                        contentEditable={false} data-id={''}
                        className={`about-page-text `}
                      >
                      </div>
                      <NewsLetters key={'news-letter'} list={aboutPageSections.newsLetters.newsLetters} />
                    </Card>
                  </div> : <></>}
                <div id="notes" className={`about-page-grid-item`} ref={releaseNotesRef}>
                  <ReleaseNotesContextProvider>
                    <ReleaseNotes />
                  </ReleaseNotesContextProvider>
                </div>
              </div>

            </div>
          </div>
        }
        <>{imageKey ? <ImgVideoUploadModal opened={ImgUploadModalOpen} onImgVideoUploadModalClosed={() => setImgUploadModalOpen(false)} contentUpdate={() => setContentUploaded(true)} posterKey={imageKey} title='Image' /> : null}</>

      </div >
    </>
  );
};

export default AboutSensing;
